(function ($) {


const Init = (() => {

  
  
  let website      = {},
      load         = {},
      resize       = {},
      scroll       = {},
      mod          = {},
      scrollOption = {},
      parallax     = {},
      drawer       = {},
      navi         = {},
      form         = {},
      formMethod   = {},
      timer        = {},
      wp           = {},
      iscroll      = {},
      loading      = {},
      totop        = {},
      mmenu        = {};
  
  website = {
    page          : document.body.dataset.page,
    url           : document.URL,
    winWidth      : window.innerWidth,
    winHeight     : window.innerHeight,
    scrollY       : window.pageYOffset,
    breakpoint    : { pc: 1200, tb: 992, sp: 768, ss: 576 },
    ua            : navigator.userAgent.toLowerCase(),
    isDesktop     : true,
    isFirst       : true,
    animationFrame: null,
  
    headTag       : $('.l-header'),
  
    act           : 'is-act',
    actOpen       : 'is-open',
  
    targetAnime : 'js-anime',
    actAnime    : 'is-anime',
    shiftPoint  : 0,
    shiftPc     : 5,
    shiftSp     : 10,
  };
  website.shiftPoint = website.breakpoint.tb;
  
  load = {
    functions: [],
    length   : 0,
  };
  
  resize = {
    functions: [],
    length   : 0,
    // fps      : 60,
    isRunning: false,
    animationFrame: null,
  };
  
  scroll = {
    functions: [],
    length   : 0,
    // fps      : 60,
    isRunning: false,
    animationFrame: null,
  };
  

  // LOAD
  const WinLoad = {
  
    init() {
      window.addEventListener('load', function(e) {
        WinLoad.update();
      });
  
      console.log('!-- load init --!');
    },
  
    add(func) {
      load.functions.push(func);
      load.length = load.functions.length;
    },
  
    remove(func) {
      load.functions.splice(func, 1);
      load.length = load.functions.length;
    },
  
    update() {
      for ( let i = 0; i < load.length; i++ ) {
        let func = load.functions[i];
        func();
      }
    },
  
  }
  
  // RESIZE
  const WinResize = {
  
    init() {
      window.addEventListener('resize', function(e) {
        if ( !resize.isRunning ) {
          resize.isRunning = true;
  
          resize.animationFrame = window.requestAnimationFrame( WinResize.update );
  
          // if ( window.requestAnimationFrame ) {
          //   window.requestAnimationFrame(() => {
          //     WinResize.update();
          //   });
          // } else {
          //   setTimeout(() => {
          //     WinResize.update();
          //   }, 1000/resize.fps);
          // }
        }
      });
  
      console.log('!-- resize init --!');
    },
  
    add(func) {
      resize.functions.push(func);
      resize.length = resize.functions.length;
    },
  
    remove(func) {
      resize.functions.splice(func, 1);
      resize.length = resize.functions.length;
    },
  
    update() {
      website.winWidth  = window.innerWidth;
      website.winHeight = window.innerHeight;
      website.ua        = navigator.userAgent.toLowerCase();
  
      for ( let i = 0; i < resize.length; i++ ) {
        let func = resize.functions[i];
        func();
      }
  
      resize.isRunning = false;
    },
  
  }
  
  // SCROLL
  const WinScroll = {
  
    init() {
      window.addEventListener('scroll', function(e) {
        if ( !scroll.isRunning ) {
          scroll.isRunning = true;
  
          scroll.animationFrame = window.requestAnimationFrame( WinScroll.update );
  
          // if ( window.requestAnimationFrame ) {
          //   window.requestAnimationFrame(() => {
          //     WinScroll.update();
          //   });
          // } else {
          //   setTimeout(() => {
          //     WinScroll.update();
          //   }, 1000/scroll.fps);
          // }
        }
      });
  
      console.log('!-- scroll init --!');
    },
  
    add(func) {
      scroll.functions.push(func);
      scroll.length = scroll.functions.length;
    },
  
    remove(func) {
      scroll.functions.splice(func, 1);
      scroll.length = scroll.functions.length;
    },
  
    update() {
      website.winHeight = window.innerHeight;
      website.scrollY = window.pageYOffset;
  
      for ( let i = 0; i < scroll.length; i++ ) {
        let func = scroll.functions[i];
        func();
      }
  
      scroll.isRunning = false;
    },
  
  }
  

  
  const Modules = {
  
    init() {
      mod = {
        targetSmooth    : 'js-scroll',
        targetTotop     : 'js-totop',
        smoothSpeed     : 600,
  
        targetTab       : 'js-tabWrap',
        targetTabTrigger: 'js-tabTrg',
        targetTabContent: 'js-tabCont',
  
        targetAccordion : 'js-accordion',
  
        targetModal : 'js-modal',
        targetModalOpen : 'js-modalOpen',
        targetModalClose: 'js-modalClose',
  
        targetAlign     : 'js-align',
        targetAlignList : [],
        alignAddClass   : 'c-alignheight',
  
        targetOfi       : '.js-ofi .ofi',
  
        targetMenu : 'js-menu',
        targetPagenavi : 'js-pagenavi',
  
        targetMegaWrap  : 'js-megaWrap',
        targetMegaTrg   : 'js-megaTrg',
        targetMegaCont  : 'js-megaCont',
  
        targetDateField : 'js-datepicker',
  
        targetPullTrg   : 'js-pulldown',
        targetPullConts : 'js-pulldown_conts',
  
      };
  
      let alignList = document.querySelectorAll('.' + mod.targetAlign);
      mod.targetAlignList = Array.prototype.slice.call(alignList, 0);
  
      this.iosCheck();
      this.deviceCheck();
      this.smoothScroll();
      this.tabBtn();
      this.accordionBtn();
      this.modalBtn();
      this.pulldown();
  
      objectFitImages(mod.targetOfi);
      Stickyfill.add('.js-sticky');
    },
  
    iosCheck() {
      if ( website.ua.indexOf('ipad') > -1 || website.ua.indexOf('iphone') > -1 || (website.ua.indexOf('macintosh') > -1 && 'ontouchend' in document ) ) {
        $('body').addClass('ios');
      } else {
        $('body').removeClass('ios');
      }
    },
  
    deviceCheck() {
      if (
        website.ua.indexOf('iphone') > -1 ||
        website.ua.indexOf('ipod') > -1 ||
        website.ua.indexOf('android') > -1 && website.ua.indexOf('mobile') > -1
      ) {
        website.isDesktop = false;
        $('body').removeClass('pc tablet').addClass('sp');
      } else if (
        website.ua.indexOf('ipad') > -1 ||
        website.ua.indexOf('Android') > -1 ||
        (website.ua.indexOf('macintosh') > -1 && 'ontouchend' in document )
      ) {
        website.isDesktop = false;
        $('body').removeClass('pc sp').addClass('tablet');
      } else {
        website.isDesktop = true;
        $('body').removeClass('tablet sp').addClass('pc');
      }
    },
  
    smoothScroll() {
      $('.' + mod.targetSmooth + ':not([href=""])').on('click', function() {
        let href     = $(this).attr("href"),
            target   = $( (href == "#") ? 'html' : href ),
            position;
        if($('.'+mod.targetMenu).length){
          shift    = ( (href != "#") ) ? $('.'+mod.targetMenu).outerHeight() : 0
        }else{
          shift    = 50;
        }
        position = target.offset().top - shift;
        $('body,html').animate({scrollTop: position}, mod.smoothSpeed, 'swing');
        return false;
      });
    },
  
    tabBtn() {
      $('.' + mod.targetTabTrigger).each(function(){
        let tabBtn = $(this).children('li').children('a');
        tabBtn.on('click',function(){
          let tabHref = $(this).attr('href'),
              tabObj = $(this).parent('li').siblings('li').children('a'),
              targetCont = $(this).closest('.'+mod.targetTab).find('.'+mod.targetTabContent),
              targetObj = targetCont.find(tabHref),
              contsObj = targetObj.siblings();
              tabObj.removeClass('is-act');
              $(this).addClass('is-act');
              contsObj.hide();
              targetObj.fadeIn();
              return false;
        });
        $(this).children('li').eq(0).children('a').click();
      });
    },
  
    accordionBtn() {
      $('.' + mod.targetAccordion).on('click', function(){
        if ( $(this).hasClass(website.act) ) {
          $(this).removeClass(website.act).next().stop().slideUp('fast');
          // $(this).removeClass(website.act);
        } else {
          $(this).addClass(website.act).next().stop().slideDown();
          // $(this).addClass(website.act);
        }
      });
    },
  
    modalBtn() {
      $('.'+mod.targetModalOpen).on('click',function(){
        $('body').addClass('is-modalOpen');
        let target = $(this).attr('href');
        $(target).fadeIn();
        return false;
      });
      $('.'+mod.targetModalClose).on('click',function(){
        $('body').removeClass('is-modalOpen');
        $(this).closest('.'+mod.targetModal).fadeOut();
        return false;
      });
    },
  
    alignHeight() {
      let alignHeight  = 0;
  
      mod.targetAlignList.forEach((target) => {
        target.style.minHeight = 'auto';
        target.classList.add(mod.alignAddClass);
        alignHeight = ( target.offsetHeight > alignHeight ) ? target.offsetHeight: alignHeight;
        target.classList.remove(mod.alignAddClass);
      });
      mod.targetAlignList.forEach((target) => {
        target.style.minHeight = alignHeight + 'px';
      });
    },
  
    megaMenu (){
      if(website.winWidth >= website.shiftPoint){
        $('.'+mod.targetMegaTrg).hover(
          function(){
            let positionY = $('.'+mod.targetMegaWrap).offset().top + $('.'+mod.targetMegaWrap).height();
            $('.'+mod.targetMegaCont).css('top', positionY+'px');
            $(this).addClass('is-act');
            $(this).children('.'+mod.targetMegaCont).addClass('is-act').stop().slideDown();
          },
          function(){
            $(this).removeClass('is-act');
            $(this).children('.'+mod.targetMegaCont).removeClass('is-act').stop().slideUp();
          }
        );
      }else{
        $('.'+mod.targetMegaTrg).children('a').on('click',function(){
          $(this).parent('.'+mod.targetMegaTrg).toggleClass('is-act');
          $(this).parent('.'+mod.targetMegaTrg).find('.'+mod.targetMegaCont).toggleClass('is-act').stop().slideToggle();
        });
      }
    },
    pulldown (){
      if(website.winWidth >= website.shiftPoint){
        $('.'+ mod.targetPullTrg).hover(
          function(){
            $(this).addClass('is-act');
            $(this).children('.'+mod.targetPullConts).addClass('is-act').stop().slideDown();
          },
          function(){
            $(this).removeClass('is-act');
            $(this).children('.'+mod.targetPullConts).removeClass('is-act').stop().slideUp();
          }
        )
      } else{
        $('.'+mod.targetPullTrg).children('a').on('click',function(){
          $(this).parent('.'+mod.targetPullTrg).find('.'+mod.targetPullConts).toggleClass('is-act').stop().slideToggle();
          if(!$(this).parent('.'+mod.targetPullTrg).hasClass('is-act')){
            $(this).parent('.'+mod.targetPullTrg).toggleClass('is-act');
            return false;
          }else{
            $(this).parent('.'+mod.targetPullTrg).toggleClass('is-act');
          }
        });
      }
    }
  }
  

  const DrawerMenu = {
  
    init() {
      drawer = {
        panel     : 'js-so_panel',
        menu      : 'js-so_menu',
        duration  : 10,
        // openclass : 'slideout-open',
        toggleList: '#js-so_toggle',
        closeList : '#js-so_panel, #js-so_menu .js-scroll',
      };
  
      this.menu();
    },
  
    menu() {
      let slideoutInst = new Slideout({
        'panel': document.getElementById(drawer.panel),
        'menu': document.getElementById(drawer.menu),
        'duration': drawer.duration,
        // 'openclass': drawer.openclass,
      });
  
      let nodeList = document.querySelectorAll(drawer.toggleList);
      let node = Array.prototype.slice.call(nodeList,0);
      node.forEach(function(elem, index){
        elem.addEventListener('click', function() {
          slideoutInst.toggle();
        });
      });
  
      let nodeListClose = document.querySelectorAll(drawer.closeList);
      let nodeClose = Array.prototype.slice.call(nodeListClose,0);
      nodeClose.forEach(function(elem, index){
        elem.addEventListener('click', function() {
          slideoutInst.close();
        });
      });
    },
  
  }
  

  const Wordpress = {
  
    init() {
      wp = {
        editorTarget: 'js-editor',
        tableClass  : 'c-tbl_responsive',
        tableInnerClass  : 'c-tbl_responsive__inner',
        iframeclass : 'c-iframe',
      }
  
      this.wpEditor();
    },
  
    wpEditor() {
      if ( document.getElementById(wp.editorTarget) != null ) {
        $( '.' + wp.editorTarget + ' table' ).each(function() {
          $(this).wrap('<div class="' + wp.tableClass + '"><div class="' + wp.tableInnerClass + '"></div></div>');
        });
        $( '.' + wp.editorTarget + ' iframe' ).each(function() {
          $(this).wrap('<div class="' + wp.iframeclass + '"></div>');
        });
      }
      // if ( $('.' + wp.editorTarget).length ) {
      //   $( '.' + wp.editorTarget + ' table' ).each(function() {
      //     $(this).wrap('<div class="' + wp.tableClass + '"></div>');
      //   });
      //   $( '.' + wp.editorTarget + ' iframe' ).each(function() {
      //     $(this).wrap('<div class="' + wp.iframeclass + '"></div>');
      //   });
      // }
    }
  
  }
  

    const Animation = {
  
      init() {
        // $(window).scroll(function()
        // {
          
        //   $('.' + website.targetAnime + ':not(.' + website.actAnime + ')').each(function(){
        //     let self = $(this),
        //         targetPosition = self.offset().top,
        //         triggerPosition,
        //         shift;
    
        //     if( website.winWidth > website.shiftPoint ) {
        //       shift = website.winHeight / website.shiftSp;
        //     } else {
        //       shift = website.winHeight / website.shiftPc;
        //     }
        //     triggerPosition = targetPosition - website.winHeight + shift;
    
        //     if( website.scrollY > triggerPosition ){
        //       self.addClass(website.actAnime);
        //     }else{
        //       self.removeClass(website.actAnime);
        //     }
        //   });
  
  
  
        // }
        // )
  
      },
  
      check() {
        $('.' + website.targetAnime + ':not(.' + website.actAnime + ')').each(function(){
          let self = $(this),
              targetPosition = self.offset().top,
              triggerPosition,
              shift;
  
          if( website.winWidth < website.shiftPoint ) {
            shift = website.winHeight / website.shiftSp;
          } else {
            shift = website.winHeight / website.shiftPc;
          }
          triggerPosition = targetPosition - website.winHeight + shift;
  
          if( website.scrollY > triggerPosition ){
            self.addClass(website.actAnime);
          }
        });
      },
  
    }
  

  const Slick = {
  
    init() {
    },
  
    topKey() {
      var autospeed = 5000;
      $('.js-keyTop_slide').on('init',function(event,slick){
        $('.slick-current').addClass('is-anime');
        $('.js-keyTop_progress').html('<div class="current"></div><div class="bar"><span></span></div><div class="next"></div>');
        $('.js-keyTop_progress').find('.current').text('0'+(slick.currentSlide+1));
        if(slick.currentSlide+2 <= slick.slideCount){
          $('.js-keyTop_progress').find('.next').text('0'+(slick.currentSlide+2));
        }else{
          $('.js-keyTop_progress').find('.next').text('01');
        }
        if(website.winWidth < website.breakpoint.sp){
          $('.js-keyTop_progress').find('.bar').children('span').animate({width: '100%'},autospeed,'linear');
        }else{
          $('.js-keyTop_progress').find('.bar').children('span').animate({height: '100%'},autospeed,'linear');
        }
      }).slick({
        fade: true,
        autoplay: true,
        autoplaySpeed: autospeed,
        speed: 1000,
        cssEase: 'linear',
        arrows: false,
        dots: true,
        appendDots : $('.js-keyTop_dots'),
        pauseOnFocus: false,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: website.breakpoint.ss,
            settings: {
              dots: false,
            }
  
          },],
      }).on({
        beforeChange: function(event, slick, currentSlide, nextSlide){
          $(".slick-slide", this).eq(currentSlide).addClass('is-prev');
          $(".slick-slide", this).eq(nextSlide).addClass('is-anime');
        },
        afterChange: function(event,slick,currentSlide){
          $(".slick-slide.is-prev", this).removeClass("is-prev is-anime");
          if(website.winWidth < website.breakpoint.sp){
            $('.js-keyTop_progress').find('.bar').children('span').css({
              'height':'100%',
              'width':'0%'
            });
          }else{
            $('.js-keyTop_progress').find('.bar').children('span').css({
              'height':'0%',
              'width':'100%'
            });
          }
          $('.js-keyTop_progress').find('.current').text('0'+(slick.currentSlide+1));
          if(slick.currentSlide+2 <= slick.slideCount){
            $('.js-keyTop_progress').find('.next').text('0'+(slick.currentSlide+2));
          }else{
            $('.js-keyTop_progress').find('.next').text('01');
          }
          if(website.winWidth < website.breakpoint.sp){
            $('.js-keyTop_progress').find('.bar').children('span').animate({width: '100%'},autospeed,'linear');
          }else{
            $('.js-keyTop_progress').find('.bar').children('span').animate({height: '100%'},autospeed,'linear');
          }
        }
      });
    },
  
  }
  



  // OFF
  // ./inc/_loading.js

  // ./inc/_totop.js

  // ./inc/_timer.js

  // ./inc/_timer.js

  // ./inc/_pagescroll.js

  // ./inc/_parallax.js




  const TopPage = {

    init() {
      top = {};
    },

    update() {
    },

  }


  const BasicPage = {

    init() {
      basic = {};
    },

    update() {
    },

  }

  


  const App = {

    init() {
      if ( website.page != 'wp-manual' && website.page != 'wp-manual-single' ) {
        this.set();
        // this.update();
      } else {
        // Modules.init();
        Wordpress.init();
      }
    },

    set() {
      Modules.init();
      DrawerMenu.init();
      Wordpress.init();
      // Animation.init();
      // PageScroll.init();
      // Parallax.init();
      // Slick.init();


      if ( website.page == 'top' ) {
        // TopPage.init();
      } else {
        // BasicPage.init();
      }

      WinLoad.add( Animation.check );
      // WinLoad.add( Modules.megaMenu );
      // WinLoad.add( Iscroll.check );

      WinResize.add( Modules.iosCheck );
      WinResize.add( Modules.deviceCheck );

      WinScroll.add( Animation.check );
      // WinScroll.add( Totop.check );
      // WinScroll.add( Iscroll.check );


      WinLoad.init();
      WinResize.init();
      WinScroll.init();

    },

    // update() {
    //   website.animationFrame = window.requestAnimationFrame( App.update );
    //   website.winWidth       = window.innerWidth;
    //   website.winHeight      = window.innerHeight;
    //   website.scrollY        = window.pageYOffset;

    //   if ( website.isDesktop ) {
    //     PageScroll.smooth();

    //     if ( website.page == 'top' ) {
    //       Parallax.moveImg();
    //     }
    //   }
    // },

  }


  App.init();

})();


jQuery(function() {
  $('.c-tab_recruit__link').on('click', function(){
    let target=$(this).attr('href');
    $(this).addClass('click').parent().siblings().children().removeClass('click');
    $(target).siblings().hide();
    $(target).show();
    return false;
  });
  $('.c-tab_recruit__link').eq(0).click();
  return false;
});

jQuery(function() {
  $('.p-kv').bgSwitcher({
      images: ['assets/img/index/mv.jpg','assets/img/index/mv_02.jpg','assets/img/index/mv_03.jpg','assets/img/index/mv_04.jpg'], 
      interval: 6000,
      loop: true,
      shuffle: false,
      effect: "fade",
      duration: 1000,
  });
});

})(jQuery);
